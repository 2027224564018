import React from 'react';

import { MerchantsPageTableRowActions } from './components/RowActions';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { MakeMerchantColumnsConfig } from './interfaces/makeMerchantColumnsConfig.interface';
import { Merchant } from 'modules/merchants/types/merchant.interface';

/**
 * Колонки таблицы клиентов
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const makeColumns = ({
  onMerchantEdit,
  onMerchantCommissionEdit,
  onMerchantDelete,
  onMerchantBalanceClick,
  onMerchantTokensEdit,
}: MakeMerchantColumnsConfig) => {
  return [
    {
      title: 'Наименование клиента',
      dataIndex: 'name',
    },
    {
      title: 'Алиас',
      dataIndex: 'aliasOrder',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_: any, merchant: Merchant) => (
        <>
          <ProtectedComponent requiredPermissions={[permissions.merchant.update]}>
            <MerchantsPageTableRowActions
              onMerchantEdit={() => onMerchantEdit(merchant.id)}
              onMerchantCommissionEdit={() => onMerchantCommissionEdit(merchant.id)}
              onMerchantDelete={() => onMerchantDelete(merchant)}
              onMerchantBalanceClick={() => onMerchantBalanceClick(merchant.id)}
              onMerchantTokensEdit={() => onMerchantTokensEdit(merchant.id)}
            />
          </ProtectedComponent>
        </>
      ),
    },
  ];
};
