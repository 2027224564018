import React from 'react';
import { Button, Space, Divider, Popconfirm } from 'antd';
import { EditOutlined, PercentageOutlined, CloseOutlined, DollarOutlined, KeyOutlined } from '@ant-design/icons';
import { Tooltip } from 'components/Tooltip';

interface Props {
  onMerchantEdit: () => void;
  onMerchantCommissionEdit: () => void;
  onMerchantDelete: () => void;
  onMerchantBalanceClick: () => void;
  onMerchantTokensEdit: () => void;
}

/**
 * Действия, которые может совершать
 *
 * @author Рябущиц Иван <telegram: @ivangeli0n>
 */
export const MerchantsPageTableRowActions: React.FC<Props> = ({
  onMerchantEdit,
  onMerchantCommissionEdit,
  onMerchantDelete,
  onMerchantBalanceClick,
  onMerchantTokensEdit,
}) => {
  return (
    <Space size="small">
      <Tooltip title="Редактировать">
        <Button
          style={{ color: '#1890FF' }}
          type="default"
          shape="circle"
          icon={<EditOutlined />}
          onClick={onMerchantEdit}
        />
      </Tooltip>
      <Tooltip title="Комиссии клиента">
        <Button
          style={{ color: '#1890FF' }}
          type="default"
          shape="circle"
          icon={<PercentageOutlined />}
          onClick={onMerchantCommissionEdit}
        />
      </Tooltip>
      <Tooltip title="Токены клиента">
        <Button
          style={{ color: '#1890FF' }}
          type="default"
          shape="circle"
          icon={<KeyOutlined />}
          onClick={onMerchantTokensEdit}
        />
      </Tooltip>
      <Tooltip title="Балансы клиента">
        <Button onClick={onMerchantBalanceClick} shape="circle" icon={<DollarOutlined />} />
      </Tooltip>
      <Divider style={{ marginLeft: 3, marginRight: 3 }} type="vertical" />
      <Popconfirm
        title="Вы действительно хотите удалить клиента?"
        okType="danger"
        okText="Удалить"
        cancelText="Нет"
        onConfirm={onMerchantDelete}
      >
        <Tooltip title="Удалить клиента">
          <Button style={{ color: '#e74c3c' }} type="default" shape="circle" icon={<CloseOutlined />} />
        </Tooltip>
      </Popconfirm>
    </Space>
  );
};
