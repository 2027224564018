import React, { useEffect, useState } from 'react';

import instance from 'utils/axios';

import { CopyOutlined, WarningOutlined } from '@ant-design/icons';
import { Modal, App, Spin, Button, Input } from 'antd';

import { useTheme } from 'contexts';
import { MerchantToken } from 'modules/merchants/types/merchantToken.interface';

interface Props {
  isShown: boolean;
  onCancel: () => void;
  merchantId?: number;
}

export const MerchantsPageTokensDrawerCreatedTokenModal: React.FC<Props> = ({ isShown, onCancel, merchantId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newToken, setNewToken] = useState<MerchantToken>();

  const { isDarkMode } = useTheme();
  const { message } = App.useApp();

  useEffect(() => {
    isShown && createToken();
  }, [isShown]);

  async function createToken() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.post('/merchants/tokens/create', {
        merchantId,
      });
      setNewToken(data);

      message.success('Токен успешно создан!');
    } catch {
      message.error('Не удалось создать токен');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={
        <div className="flex flex-col gap-y-4 justify-center items-center">
          <WarningOutlined className="text-red-500 text-3xl pt-4" />
          <div className="font-semibold text-red-500 px-4 text-center">
            Внимание! Новый токен будет отображен всего один раз, скопируйте его в безопасное место
          </div>
        </div>
      }
      open={isShown}
      onCancel={onCancel}
      centered
      footer={
        <Button type="primary" onClick={onCancel}>
          Подтвердить
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <div className="flex py-4">
          <Input disabled value={newToken?.token} style={{ color: isDarkMode ? 'white' : 'black' }} />
          <Button
            onClick={async () => {
              try {
                newToken?.token && (await navigator.clipboard.writeText(newToken.token));

                message.success('Скопировано!');
              } catch {
                message.error('Ошибка копирования');
              }
            }}
          >
            <CopyOutlined />
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
