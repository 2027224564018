import React from 'react';

import { Button, Popconfirm, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useTheme } from 'contexts';

import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';

import dayjs from 'dayjs';
import { MerchantToken } from 'modules/merchants/types/merchantToken.interface';
import { Tooltip } from 'components/Tooltip';

export interface IMakeColumnsConfig {
  onRevoke: (token: MerchantToken) => void;
}

export const makeColumns = ({ onRevoke }: IMakeColumnsConfig) => {
  const { isDarkMode } = useTheme();

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Дата создания',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (_: any, token: MerchantToken) => {
        return <>{dayjs(token.createdAt).format(DEFAULT_DATETIME_STRING_FORMAT)}</>;
      },
    },
    {
      title: 'Дата истечения',
      key: 'expiresAt',
      dataIndex: 'expiresAt',
      render: (_: any, token: MerchantToken) => {
        return (
          <>
            {!token.expiresAt ? (
              <div className="text-green-500 font-semibold">Бессрочный</div>
            ) : (
              <div>
                {dayjs(token.expiresAt, DEFAULT_DATETIME_STRING_FORMAT).diff(dayjs(Date.now())) >= 0 ? (
                  <div className="text-green-500 font-semibold">
                    {dayjs(token.expiresAt).format(DEFAULT_DATETIME_STRING_FORMAT)}
                  </div>
                ) : (
                  <div className="text-red-500 font-semibold">
                    {dayjs(token.expiresAt).format(DEFAULT_DATETIME_STRING_FORMAT)}
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Последнее использование',
      key: 'lastUsedAt',
      dataIndex: 'lastUsedAt',
      render: (_: any, token: MerchantToken) => {
        return <>{token.lastUsedAt && dayjs(token.lastUsedAt).format(DEFAULT_DATETIME_STRING_FORMAT)}</>;
      },
    },
    {
      title: 'Ключ авторизации',
      key: 'token',
      dataIndex: 'token',
      render: (_: any, token: MerchantToken) => {
        return <Input disabled value={token.token} style={{ color: isDarkMode ? 'white' : 'black' }} />;
      },
    },
    {
      title: 'Действия',
      render: (_: any, token: MerchantToken) => {
        const isExpired = dayjs(token.expiresAt, DEFAULT_DATETIME_STRING_FORMAT).diff(dayjs(Date.now())) < 0;

        if (token.expiresAt && isExpired) {
          return null;
        }

        return (
          <Popconfirm
            title="Вы действительно хотите отозвать токен?"
            okType="danger"
            okText="Отозвать"
            cancelText="Нет"
            onConfirm={() => onRevoke(token)}
          >
            <Tooltip title="Отозвать">
              <Button style={{ color: '#e74c3c' }} type="default" shape="circle" icon={<CloseOutlined />} />
            </Tooltip>
          </Popconfirm>
        );
      },
    },
  ];
};
