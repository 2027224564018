import React, { useState, useEffect } from 'react';
import { Drawer, Table, Spin, Typography, App } from 'antd';
import { makeColumns } from './tableColumns';
import instance from 'utils/axios';
import { MerchantBalance } from 'modules/merchants/types/merchantBalance.interface';

interface Props {
  merchantId?: number;
  onClose: () => void;
}

export const MerchantsPageBalancesDrawer: React.FC<Props> = ({ merchantId, onClose }) => {
  const [balances, setBalances] = useState<MerchantBalance[]>([]);
  const [balancesByCurrencies, setBalancesByCurrencies] = useState<{ currencyCode: string; amount: number }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message } = App.useApp();

  useEffect(() => {
    if (!merchantId) {
      setBalances([]);
      setBalancesByCurrencies([]);

      return;
    }

    fetchBalances();
    fetchBalancesByCurrencies();
  }, [merchantId]);

  async function fetchBalancesByCurrencies() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/merchants/balances-by-currencies', {
        params: { merchantId },
      });

      setBalancesByCurrencies(data);
    } catch {
      message.error('Не удалось получить балансы клиента по валютам');
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchBalances() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/merchants/balances', {
        params: { merchantId, perPage: 100 },
      });

      setBalances(data);
    } catch {
      message.error('Не удалось получить балансы клиента');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer open={!!merchantId} width="50%" title="Балансы клиента" onClose={onClose}>
      <Spin spinning={isLoading}>
        <Typography.Title level={5}>Балансы по валютам</Typography.Title>
        <Table
          columns={[
            {
              title: 'Валюта',
              dataIndex: 'currencyCode',
              key: 'currencyCode',
            },
            {
              title: 'Сумма',
              dataIndex: 'amount',
              key: 'amount',
              render: (amount) => <span>{Number(amount).toLocaleString('ru-RU')}</span>,
            },
          ]}
          dataSource={balancesByCurrencies}
          pagination={false}
        />
        <Typography.Title level={5} className="mt-4">
          Балансы по банкам
        </Typography.Title>
        <Table columns={makeColumns()} dataSource={balances} pagination={false} />
      </Spin>
    </Drawer>
  );
};
