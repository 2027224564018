import React, { useEffect } from 'react';

import { MerchantBalancesPageTable } from './components/Table';

import { filtersSchema } from './filters';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { FetchMerchantsBalancesParams } from 'modules/merchants/store/balances/interfaces/fetchMerchantsBalancesParams.interface';
import { changePagination, fetchMerchantsBalances } from 'modules/merchants/store/balances/actions';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

export const MerchantsBalancesPage: React.FC = () => {
  const {
    merchantsBalances,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector(({ merchantsBalances }) => merchantsBalances);

  const dispatch = useAppDispatch();

  const { memoFilters, setMemoFilters } = useMemoFilters<FetchMerchantsBalancesParams>();

  useEffect(() => {
    dispatch(fetchMerchantsBalances({ ...memoFilters, page, perPage }));
  }, [memoFilters, page, perPage]);

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(changePagination(page, perPage));
  }

  return (
    <>
      <Filters headerTitle="Балансы клиентов" filters={filtersSchema} value={memoFilters} onSubmit={setMemoFilters} />
      <MerchantBalancesPageTable merchants={merchantsBalances} isLoading={isLoading} />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
