import React, { useEffect, useState } from 'react';

import { Button, App } from 'antd';

import { PlusCircleOutlined } from '@ant-design/icons';

import { MerchantsPageTable } from './components/Table';

import { Filters } from 'components/Filters';
import { filtersSchema } from './filters';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { MerchantsPageCreateDrawer } from './components/CreateDrawer';
import { MerchantsPageEditDrawer } from './components/EditDrawer';
import { MerchantsPageEditCommissionsDrawer } from './components/EditCommissions';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { MerchantsPageBalancesDrawer } from './components/BalancesDrawer';
import { permissions } from 'policies/permissions';
import { Pagination } from 'components/Pagination';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { MerchantsPageTokensDrawer } from './components/TokensDrawer';
import { FetchMerchantsParams } from 'modules/merchants/store/merchants/interfaces/fetchMerchantsParams.interface';
import { changePagination, deleteMerchant, fetchMerchants } from 'modules/merchants/store/merchants/actions';
import { Merchant } from 'modules/merchants/types/merchant.interface';

export const MerchantsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchMerchantsParams>();
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [merchantIdForEditCommissions, setMerchantIdForEditCommissions] = useState<number>();
  const [merchantIdForBalances, setMerchantIdForBalances] = useState<number>();
  const [merchantIdForEdit, setMerchantIdForEdit] = useState<number>();
  const [merchantIdForTokens, setMerchantIdForTokens] = useState<number>();

  const { message } = App.useApp();

  const {
    merchants,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.merchants);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch();
  }, [memoFilters, page, perPage]);

  async function fetch() {
    try {
      await dispatch(fetchMerchants({ ...memoFilters, page, perPage }));
    } catch {
      message.error('Не удалось получить список клиентов. Попробуйте позже.');
    }
  }

  async function handleDelete(merchant: Merchant) {
    const messageKey = 'deleteMerchant_' + merchant.id;

    try {
      message.open({
        key: messageKey,
        type: 'loading',
        duration: 0,
        content: `Клиент ${merchant.name} находится в процессе удаления`,
      });

      await dispatch(deleteMerchant(merchant.id));

      message.open({
        key: messageKey,
        type: 'success',
        duration: 3,
        content: `Клиент ${merchant.name} удален`,
      });
    } catch {
      message.open({
        key: messageKey,
        type: 'error',
        duration: 3,
        content: 'Ошибка удаления клиента',
      });
    }
  }

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(changePagination(page, perPage));
  }

  return (
    <>
      <MerchantsPageCreateDrawer isShown={isAddModalVisible} onCancel={() => setIsAddModalVisible(false)} />
      <MerchantsPageEditDrawer merchantId={merchantIdForEdit} onCancel={() => setMerchantIdForEdit(undefined)} />
      <MerchantsPageEditCommissionsDrawer
        merchantId={merchantIdForEditCommissions}
        onCancel={() => setMerchantIdForEditCommissions(undefined)}
      />
      <MerchantsPageBalancesDrawer
        merchantId={merchantIdForBalances}
        onClose={() => setMerchantIdForBalances(undefined)}
      />
      <MerchantsPageTokensDrawer merchantId={merchantIdForTokens} onClose={() => setMerchantIdForTokens(undefined)} />
      <Filters
        headerTitle="Клиенты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.merchant.create]}>
            <Button
              className="ml-0"
              onClick={() => setIsAddModalVisible(true)}
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined />}
            >
              Добавить
            </Button>
          </ProtectedComponent>
        )}
      />
      <MerchantsPageTable
        merchants={merchants}
        isLoading={isLoading}
        onMerchantEdit={setMerchantIdForEdit}
        onMerchantCommissionEdit={setMerchantIdForEditCommissions}
        onMerchantDelete={handleDelete}
        onMerchantBalanceClick={setMerchantIdForBalances}
        onMerchantTokensEdit={setMerchantIdForTokens}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
