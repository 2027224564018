import React, { useMemo } from 'react';
import { Table, Form } from 'antd';
import { makeColumns } from './columns';
import { MerchantBalance } from 'modules/merchants/types/merchantBalance.interface';

interface Props {
  merchants: MerchantBalance[];
  isLoading: boolean;
}

/**
 * Таблица клиентов
 *
 * @author Рассохин Алексей <telegram: @alras63>
 */
export const MerchantBalancesPageTable: React.FC<Props> = ({ merchants, isLoading }) => {
  const [form] = Form.useForm<MerchantBalance>();

  const columns = useMemo(() => makeColumns(), []);

  return (
    <>
      <Form form={form} component={false}>
        <Table
          size={'small'}
          scroll={{ x: true }}
          columns={columns}
          loading={isLoading}
          rowKey={(customer: { id: number }) => customer.id}
          dataSource={merchants}
          pagination={false}
          bordered
        />
      </Form>
    </>
  );
};
