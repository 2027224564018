import React from 'react';
import { Link } from 'react-router-dom';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import LogoWhiteBlackIconSvg from 'assets/svg/Logo-white-black.svg';

import { useTheme } from 'contexts';
import { ThemeSwitcher } from 'components/ThemeSwitcher/ThemeSwitcher';

interface Props {
  isSiderShown: boolean;
  onSiderToggle: (isSiderShown: boolean) => void;
}

export const LayoutHeader: React.FC<Props> = ({ isSiderShown, onSiderToggle }) => {
  const {
    currentTheme: {
      token: { colorPrimary },
    },
  } = useTheme();

  return (
    <div
      className="flex justify-between items-center w-full h-12 fixed z-10"
      style={{ background: colorPrimary, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)', zIndex: 1115 }}
    >
      <Button
        type="text"
        icon={isSiderShown ? <CloseOutlined /> : <MenuOutlined />}
        onClick={() => onSiderToggle(isSiderShown)}
        style={{ backgroundColor: colorPrimary, color: '#ffffffd9' }}
        className="ml-5"
      ></Button>
      <div>
        <Link to="/">
          <img src={LogoWhiteBlackIconSvg} className="w-36 m-auto pt-3 pb-3" alt="Logo" />
        </Link>
      </div>
      <div className="mr-6">
        <ThemeSwitcher />
      </div>
    </div>
  );
};
