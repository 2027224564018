import { useMemo } from 'react';
import { TableMerchantCommission } from './types';
import { useBanksFromServer } from 'hooks/useDataFromServer';
import { MerchantTariffTypes } from 'modules/merchants/enums/merchantTariffTypes.enum';
import { MerchantCommission } from 'modules/merchants/types/merchantCommission.interface';

interface IConfig {
  commissions: MerchantCommission[] | undefined;
}

export function useGeneratedTableData({ commissions }: IConfig) {
  const [providers] = useBanksFromServer();

  function normalizeValue(value: number): number {
    return parseFloat((value * 100).toFixed(2));
  }

  return useMemo<TableMerchantCommission[]>(() => {
    return providers.map((provider) => {
      const p2pCommission = commissions?.find(
        (commission) => commission.bankId === provider.id && commission.tariffType === MerchantTariffTypes.P2P
      );
      const c2cCommission = commissions?.find(
        (commission) => commission.bankId === provider.id && commission.tariffType === MerchantTariffTypes.C2C
      );

      return {
        provider: provider,
        currencyCode: provider.currencyCode,
        p2pIncomingValue: p2pCommission?.incomingValue && normalizeValue(p2pCommission.incomingValue),
        p2pOutcomingValue: p2pCommission?.outcomingValue && normalizeValue(p2pCommission.outcomingValue),
        c2cIncomingValue: c2cCommission?.incomingValue && normalizeValue(c2cCommission.incomingValue),
        c2cOutcomingValue: c2cCommission?.outcomingValue && normalizeValue(c2cCommission.outcomingValue),
      };
    });
  }, [providers, commissions]);
}
