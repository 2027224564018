import { MerchantCommissionStep } from 'modules/merchants/pages/list/components/CreateDrawer/interfaces/merchantCommissionStep.interface';

export const emptyCommissionRow: MerchantCommissionStep = {
  incomingValue: {
    p2p: undefined,
    c2c: undefined,
  },
  outcomingValue: {
    p2p: undefined,
    c2c: undefined,
  },
  currencyCode: '',
};
