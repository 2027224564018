import React from 'react';
import { Table } from 'antd';

import { makeColumns } from './columns';
import { MakeMerchantColumnsConfig } from './interfaces/makeMerchantColumnsConfig.interface';
import { Merchant } from 'modules/merchants/types/merchant.interface';

interface Props extends MakeMerchantColumnsConfig {
  merchants: Merchant[];
  isLoading: boolean;
}

/**
 * Компонент таблицы страницы клиентов
 *
 * @author Собянин Виктор <telegram: @victorsobyanin>
 */
export const MerchantsPageTable: React.FC<Props> = ({
  merchants,
  isLoading,
  onMerchantEdit,
  onMerchantCommissionEdit,
  onMerchantDelete,
  onMerchantBalanceClick,
  onMerchantTokensEdit,
}) => {
  return (
    <Table
      columns={makeColumns({
        onMerchantEdit,
        onMerchantCommissionEdit,
        onMerchantDelete,
        onMerchantBalanceClick,
        onMerchantTokensEdit,
      })}
      dataSource={merchants}
      loading={isLoading}
      pagination={false}
      rowKey={(customer) => customer.id}
    />
  );
};
