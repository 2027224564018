import React, { useState, useEffect } from 'react';

import { Drawer, Table, Spin, App, Space, Button, Popconfirm } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { MerchantsPageTokensDrawerCreatedTokenModal } from './components/CreatedTokenModal';

import { makeColumns } from './columns';

import instance from 'utils/axios';
import { MerchantToken } from 'modules/merchants/types/merchantToken.interface';

interface Props {
  merchantId?: number;
  onClose: () => void;
}

export const MerchantsPageTokensDrawer: React.FC<Props> = ({ merchantId, onClose }) => {
  const [tokens, setTokens] = useState<MerchantToken[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTokensModalVisible, setIsTokensModalVisible] = useState(false);

  const { message } = App.useApp();

  useEffect(() => {
    if (!merchantId) {
      setTokens([]);

      return;
    }

    fetchTokens();
  }, [merchantId]);

  async function fetchTokens() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/merchants/tokens', {
        params: { merchantId },
      });

      setTokens(data);
    } catch {
      message.error('Не удалось получить токены клиента');
    } finally {
      setIsLoading(false);
    }
  }

  async function revokeToken(token: MerchantToken) {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.post('/merchants/tokens/revoke', {
        merchantId,
        tokenId: token.id,
      });

      setTokens(
        tokens.map((el) => {
          if (el.id === data.id) {
            return data;
          }
          return el;
        })
      );

      message.success('Токен успешно отозван!');
    } catch {
      message.error('Не удалось отозвать токен');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer
      open={!!merchantId}
      width="50%"
      title={
        <Space size="small" className="flex justify-between items-center">
          <h3>Токены клиента</h3>
          <Popconfirm
            title="Вы действительно хотите создать токен?"
            okType="primary"
            okText="Создать"
            cancelText="Отменить"
            onConfirm={() => setIsTokensModalVisible(true)}
          >
            <Button className="ml-0" type="primary" shape="round" icon={<PlusCircleOutlined />}>
              Создать токен
            </Button>
          </Popconfirm>
        </Space>
      }
      onClose={onClose}
    >
      <Spin spinning={isLoading}>
        <Table
          columns={makeColumns({
            onRevoke: (token) => revokeToken(token),
          })}
          dataSource={tokens}
          pagination={false}
        />
      </Spin>
      <MerchantsPageTokensDrawerCreatedTokenModal
        merchantId={merchantId}
        isShown={isTokensModalVisible}
        onCancel={() => {
          setIsTokensModalVisible(false);
          fetchTokens();
        }}
      />
    </Drawer>
  );
};
